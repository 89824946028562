.host {
  padding: 0 0 0 10px;
  min-height: auto;
  justify-content: space-between;
  align-items: center;
  box-sizing: content-box;
}

.leftSide {
  display: flex;
  justify-content: flex-end;
}

.label {
  font-size: 1.125rem;
  margin-right: 16px;
}

.button {
  margin: 0 4px;
  min-width: var(--toolbar-button-width);
}

.button:last-child {
  margin-right: 0;
}
