.buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.button {
  margin: 0 4px;
  min-width: var(--default-button-width);
}
