.noDataText {
  color: var(--main-text-color);
}

.table {
  border: none;
}

.table [class*="MuiDataGrid-row"]:hover {
  background-color: rgb(32 131 165 / 6%);
}

.table [class*="columnSeparator"] {
  display: none;
}

.table [class*="columnHeaderTitle"] {
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-left: 0;
  padding-right: 0;
}

.table [class*="MuiDataGrid-cell"] {
  border-bottom: none;
}

.table [class*="MuiDataGrid-cell"]:focus,
.table [class*="MuiDataGrid-cell"]:focus-within,
.table [class*="MuiDataGrid-columnHeader"]:focus-within,
.table [class*="MuiDataGrid-columnHeader"]:focus {
  outline: none;
}

.table.zebra [class*="Mui-even MuiDataGrid-row"] {
  background-color: #f3f5f7;
}

.table:not(.noPagination) [class*="footerContainer"] {
  border-top: 1px solid var(--table-border-color);
}

.progressbar {
  position: absolute;
  top: 0;
  width: 100%;
}
