.host {
  display: flex;
  overflow: hidden;
  position: relative;
  flex-grow: 1;
  min-height: inherit;
}

.link > span:first-child {
  font-size: 0.875rem;
}

.container {
  margin-left: var(--sidebar-width);
  padding: 24px 40px 56px;
  flex-grow: 1;
  min-width: 0;
}

.pageContainer {
  background: var(--main-background);
}

.navItem {
  padding-top: 12px;
  padding-bottom: 12px;
}

.divider {
  background-color: #1e3036;
}
