.host {
  padding: 0 10px;
  min-height: auto;
  align-items: center;
  justify-content: space-between;
}

.label {
  font-size: 1.125rem;
  margin-right: 16px;
}

.button {
  margin: 0 4px;
  min-width: var(--toolbar-button-width);
}

.button:last-child {
  margin-right: 0;
}
