:root {
  --main-text-color: #4a4a4a;
  --main-background: #eff3f4;
  --appbar-background: #071b22;
  --sidebar-background: var(--appbar-background);
  --main-color: #2e384d;
  --primary-color: #2083a5;
  --secondary-color: #0bb7b7;
  --error-color: #b3251b;
  --info-color: #033a3a;
  --input-background: #f4f9fa;
  --table-border-color: #e0e0e0;
  --font-semplicita-pro-bold: "SemplicitaProBold", "Roboto", "Helvetica", "Arial", sans-serif;
  --font-semplicita-pro-semi-bold: "SemplicitaProSemiBold", "Roboto", "Helvetica", "Arial", sans-serif;
  --bottom-bar-height: 82px;
  --register-button-width: 200px;
  --default-button-width: 144px;
  --toolbar-button-width: 128px;
  --sidebar-width: 276px;
  --animation-duration: 250ms;
  --component-radius: 4px;
}
