.host {
  margin-bottom: 8px;
}

.host [class*="separator"],
.link,
.text {
  font-size: 0.75rem;
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.text {
  color: rgb(0 0 0 / 50%);
}

.placeholder {
  height: 32px;
}
