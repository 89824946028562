.host {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 4px;
  position: relative;
}

.host .icon > svg {
  width: 56px;
  height: 56px;
}

.value {
  font-size: 1.5rem;
  width: 100%;
  line-height: 1;
}

.title {
  width: 100%;
  padding-bottom: 4px;
}

.progressbar {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -32px;
}
