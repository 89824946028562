.host {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.host:hover {
  text-decoration: underline;
}

.icon {
  width: 14px;
  height: 14px;
  margin-left: 4px;
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
