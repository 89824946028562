.input > label {
  color: var(--main-color);
  font-size: 0.875rem;
  font-weight: 600;
  transform: scale(1);
}

.input + div {
  margin-top: 24px;
}

.input[class*="-adornedEnd"] {
  padding-right: 6px;
}
