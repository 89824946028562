.input.fullWidth {
  width: 100%;
}

.input > label {
  color: var(--main-color);
  font-size: 0.875rem;
  font-weight: 600;
  transform: scale(1);
}

.input > [class*="MuiInput-root"] {
  background: #fff;
  padding: 4px;
  width: 128px;
}

.host [class*="fullWidth"] > [class*="MuiInput-root"] {
  width: 100%;
}

.delimiter {
  display: none;
}
