.icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.radioGroup {
  display: inline-flex;
  margin-top: 16px;
}

.skeletons {
  margin-top: 16px;
}

.skeleton {
  margin: 8px 8px 8px 0;
}

.skeletonHost {
  display: flex;
  align-items: center;
  width: 100%;
}

.label {
  text-transform: capitalize;
  position: relative;
}

.noColor {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--main-text-color);
}
