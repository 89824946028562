.select {
  position: relative;
}

.host .label {
  margin-bottom: 8px;
  font-weight: 600;
  pointer-events: auto;
}

.progressbar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.wrapper {
  width: 100%;
  position: relative;
}

.host [class*="MuiInputBase-root"] {
  width: 100%;
  background: #fff;
}
