.host {
  border-radius: var(--component-radius);
}

.host[class*="colorSecondary"] {
  color: #fff;
}

.dark {
  background-color: rgb(7 27 34 / 84%);
  color: #fff;
}
