.host > [class*="MuiPaper"] {
  width: var(--sidebar-width);
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: var(--sidebar-background);
  border-right: none;
  color: #fff;
}

.logo {
  object-fit: contain;
  width: auto;
  margin-bottom: 32px;
}

.card {
  border: 0;
  background: var(--primary-color);
  color: #fff;
  overflow: visible;
}
