@import url("variables.css");
@import url("fonts.css");

html > body:not(.register-page) {
  background: var(--main-background);
}

.app-wrapper {
  min-height: 100vh;
  color: var(--main-text-color);
}

.app-wrapper a:not([class*="Mui"]) {
  color: var(--primary-color);
}

.app-wrapper .MuiInput-formControl {
  background: var(--input-background);
  font-size: 0.875rem;
}

.app-wrapper .MuiInput-formControl:not(.MuiInputBase-multiline) {
  height: 36px;
}

.app-wrapper input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
  box-shadow: inset 0 0 0 1000px var(--input-background);
}

.inputIcon {
  fill: rgb(0 0 0 / 50%);
}

.bold {
  font-weight: 500;
}

.marginPageTitle {
  margin-bottom: 32px;
}

.paperPadding {
  padding: 24px;
}

.noUnderlineLink {
  text-decoration: none;
}

.noUnderlineLink:hover {
  text-decoration: underline;
}

.emptyGrid.MuiGrid-item {
  padding: 0;
}

.text-uppercase {
  text-transform: uppercase;
}

.MuiFormLabel-root {
  color: var(--main-color);
  font-size: 0.875rem;
  font-weight: 600;
}

.MuiTabs-root {
  border-bottom: 1px solid rgb(224 224 224 / 100%);
}

.MuiTab-root {
  min-height: 64px;
}

.MuiTab-textColorInherit {
  opacity: 0.5;
}

.textOverflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
