.buttons {
  justify-content: space-between;
  display: flex;
}

.button {
  margin: 0 4px;
  min-width: 128px;
}

.leftPart .button {
  margin-left: 0;
}

.button:last-child {
  margin-right: 0;
}
