.input {
  position: relative;
}

.input > label {
  color: var(--main-color);
  font-size: 0.875rem;
  font-weight: 600;
  transform: scale(1);
}

.progressbar {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

[class*="MuiAutocomplete-option"],
[class*="MuiAutocomplete-noOptions"] {
  font-size: 0.875rem;
}
