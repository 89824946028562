.menu {
  height: 100%;
}

.link > span:first-child {
  font-size: 0.875rem;
}

.navItem {
  padding-top: 12px;
  padding-bottom: 12px;
}

.menuActive {
  background: #34464d;
}
