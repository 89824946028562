.host {
  padding: 16px 16px 24px;
}

.formStatus {
  display: flex;
  align-items: center;
  gap: 12px;
}

.label[class*="MuiFormLabel-root"] {
  color: #fff;
}

.host [class*="MuiSwitch-track"] {
  background-color: #515f64;
  opacity: 1;
}

.host [class*="MuiTypography-root"] {
  font-weight: 500;
}

.externalLinkMui {
  color: #fff;
  font-size: 0.75rem;
  display: flex;
}

.externalLinkMui:hover {
  text-decoration: none;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.button {
  border-color: #fff;
  color: #fff;
  min-width: 112px;
}

.icon {
  width: 12px;
}
