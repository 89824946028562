.host {
  height: 270px;
  position: relative;
}

.progressbar {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -60px;
  margin-top: -60px;
}
