@font-face {
  font-family: SemplicitaProSemiBold;
  src: url("assets/fonts/SemplicitaProSemiBold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: SemplicitaProBold;
  src: url("assets/fonts/SemplicitaProBold.woff") format("woff");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}
