.input > label {
  color: var(--main-color);
  font-size: 0.875rem;
  font-weight: 600;
  transform: scale(1);
}

.input + div {
  margin-top: 24px;
}

.arrows input[type="number"] {
  appearance: textfield;
  margin: 0;
}

.arrows input[type="number"]::-webkit-inner-spin-button,
.arrows input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}
