[class*="MuiDataGrid"] .visibleOnHover {
  opacity: 0;
  transition: opacity var(--animation-duration);
}

[class*="MuiDataGrid"]:hover > .visibleOnHover {
  opacity: 1;
}

.statusWrap {
  display: flex;
  align-items: center;
}

.dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-right: 16px;
}

.statusWrap .dot[class*="active"] {
  background-color: #00e386;
}

.statusWrap .dot[class*="unregistered"] {
  background-color: #a6a6a6;
}

.statusWrap .dot[class*="unregistered_pending"] {
  background-color: #b01b11;
}

.statusWrap .dot[class*="request_pending"] {
  background-color: #2083a5;
}
